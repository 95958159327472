<template>
  <v-dialog max-width="85%" v-model="showDialog">
    <v-card class="influencer-dialog-wrapper">
      <v-card-title>
        <div>
          <div class="headline">{{ fullName }}</div>
          <div class="subtitle-1 light-grey">
            {{ influencer.birthday | date_to_age }} yo /
            {{ influencer.gender === "MALE" ? "Male" : "Female" }}
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <template v-if="influencer.channels.length > 1">
          <v-layout wrap class="mb-4">
            <v-flex md4>
              <influencer-channel-select
                v-model="selectedInfluencerChannel"
                :items="influencer.channels"
              ></influencer-channel-select>
            </v-flex>
          </v-layout>

          <v-divider></v-divider>
          <br />
        </template>

        <v-layout wrap class="mb-4">
          <v-flex md4 class="text-center align-self-center">
            <influencer-channel-avatar
              :user="influencer.user"
              :channel="selectedInfluencerChannel"
              outlined
            ></influencer-channel-avatar>
          </v-flex>
          <v-flex md8 xs12>
            <div class="text-right">
              <v-btn
                v-if="selectedInfluencerChannel"
                color="primary"
                class="mb-3"
                outlined
                :href="
                  `https://www.instagram.com/${selectedInfluencerChannel.username}`
                "
                target="_blank"
                :block="$vuetify.breakpoint.xsOnly"
              >
                See {{ selectedInfluencerChannel.channel.display_name }} profile
              </v-btn>
            </div>

            <v-container
              v-if="selectedInfluencerChannel.categories.length > 0"
              grid-list-md
              class="p-0"
            >
              <v-layout wrap>
                <v-flex
                  v-for="category in selectedInfluencerChannel.categories"
                  :key="category.id"
                >
                  <v-card flat class="flex-card bordered" height="100%">
                    <v-card-text class="text-center pb-0 pt-2 grow">
                      <v-icon medium>{{ category.icon }}</v-icon>

                      <div class="primary--text">
                        {{ category.display_name }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>

        <influencer-channel-stats-container
          v-if="selectedInfluencerChannel"
          :influencer-channel="selectedInfluencerChannel"
          outlined
        ></influencer-channel-stats-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showDialog = false">Close</v-btn>
      </v-card-actions>
      <v-flex class="card-favorite cursor-pointer" v-if="isBusiness">
        <favorite-influencer
          :influencer="influencer"
          @change="updateFavorite"
        ></favorite-influencer>
      </v-flex>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import InfluencerChannelSelect from "../selects/InfluencerChannelSelect";
import date_to_age from "@/helpers/filters/date_to_age";
import InfluencerChannelStatsContainer from "../containers/InfluencerChannelStatsContainer";
import InfluencerChannelAvatar from "../avatars/InfluencerChannelAvatar";
import FavoriteInfluencer from "../inputs/FavoriteInfluencer";
export default {
  components: {
    InfluencerChannelAvatar,
    InfluencerChannelStatsContainer,
    InfluencerChannelSelect,
    FavoriteInfluencer
  },
  filters: { date_to_age },
  props: {
    value: {
      required: true,
      type: Boolean,
      default: false
    },
    influencer: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    selectedInfluencerChannel: null
  }),
  computed: {
    ...mapState("subscriptions", ["showSubscriptionDialog"]),
    ...mapGetters("core/auth", ["isBusiness"]),
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    fullName() {
      return (
        this.influencer.user.first_name + " " + this.influencer.user.last_name
      );
    }
  },
  methods: {
    updateFavorite(event) {
      this.$emit("change", { value: event, uuid: this.influencer.uuid });
    }
  },
  watch: {
    showSubscriptionDialog(newValue) {
      if (newValue) {
        this.showDialog = false;
      }
    },
    influencer: {
      immediate: true,
      handler() {
        this.selectedInfluencerChannel = this.influencer.channels[0];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.influencer-dialog-wrapper {
  position: relative;
  .card-favorite {
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
</style>
