<template>
  <div>
    <v-select
      v-model="selected"
      ref="ageGenderSelect"
      :items="formattedAgeGenders"
      :loading="loading"
      hide-no-data
      hide-selected
      return-object
      label="Follower age distribution"
      item-text="name"
      placeholder="Select an age distribution"
      color="primary"
      chips
      multiple
    >
      <template slot="selection" slot-scope="data">
        <v-chip
          :input-value="data.selected"
          close
          close-icon="fa fa-times"
          class="chip--select-multi"
          @click:close="remove(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
    </v-select>

    <v-layout flex v-for="age_gender in selected" :key="age_gender.id">
      <v-flex xs6
      class="mr-4">
        <v-text-field
          :value="age_gender.name"
          label="Age distribution"
          color="primary"
          disabled
        ></v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="age_gender.followers"
          label="Minimum follower requirements"
          color="primary"
          @change="ageGenderChange($event, age_gender)"
        ></v-text-field>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      require: true
    }
  },
  data: () => ({
    loading: false,
    ageGenders: []
  }),
  computed: {
    selected: {
      get() {
        let result = [];
        if (this.value && this.value.length > 0) {
          result = this.ageGenders
            .filter(o1 => this.value.some(o2 => o1.id === Number(o2.id)))
            .map(age_gender => {
              let name = "Other";
              if (age_gender.gender === "MALE") {
                name = "Male";
              } else if (age_gender.gender === "FEMALE") {
                name = "Female";
              }
              name += " - " + age_gender.age;
              return {
                name: name,
                id: Number(age_gender.id),
                followers: this.value.find(id => id.id === age_gender.id)
                  .followers
              };
            });
        }
        return result;
      },
      set(val) {
        // Sort of a hack to close menu after selecting
        this.$refs.ageGenderSelect.isMenuActive = false;
        this.$emit("input", val);
      }
    },

    formattedAgeGenders() {
      let ageGenders = this.ageGenders.map(age_gender => {
        let name = "Other";
        if (age_gender.gender === "MALE") {
          name = "Male";
        } else if (age_gender.gender === "FEMALE") {
          name = "Female";
        }
        name += " - " + age_gender.age;

        return {
          name: name,
          id: Number(age_gender.id),
          followers: 250
        };
      });
      // If already selected append to cities
      if (this.value && this.value.length > 0) {
        this.value.forEach(element => {
          let exists = ageGenders.find(item => item.id === element.id);
          if (exists && exists.length === 0) {
            ageGenders.push(element);
          }
        });
      }
      return ageGenders;
    }
  },
  methods: {
    ...mapActions("core", ["getInfluencerAgeGenders"]),
    ageGenderChange(event, item) {
      item.followers = Number(event);
      this.$emit("input", this.selected);
    },
    remove(item) {
      let index = this.selected.map(item => item.id).indexOf(item.id);

      if (index !== -1) {
        this.selected.splice(index, 1);
        this.$emit("input", this.selected);
      }
    }
  },
  created() {
    this.getInfluencerAgeGenders().then(data => {
      this.ageGenders = data;
    });
  }
};
</script>
